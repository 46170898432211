import React from "react";
import clsx from "clsx";
import { Link, PageProps } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import MainLayout from "../components/templates/MainLayout";
import { Box, Button, Grid, Icon, makeStyles, Typography } from "@material-ui/core";
import Seo from "../components/atoms/Seo";
import ArticleListItem from "../components/atoms/articles/ArticleListItem";
import ArticleList from "../components/atoms/articles/ArticleList";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {},
  sectionSpace: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pageHeaderTitle: {
    //padding: theme.spacing(4, 0, 6, 0),
  },
  pageHeaderSubtitle: {
    //
    //paddingLeft:theme.spacing(2),
  },
  sectionHead: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionButtons: {
    width: 350,
  },
  buttonsContainer:{
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]:{
      flexDirection: "column",
      height: "6rem",
      alignItems: "center",
    },
  },
  sectionDetails: {
    //paddingTop: theme.spacing(2),
  },
  section3: {
    //margin: theme.spacing(3, 1, 1),
  },
}));

// markup
const IndexPage = () => {

  const classes = useStyles();
  const { site } = useStaticQuery<GatsbyTypes.IndexQuery>(graphql`
    query Index {
      posts: allContentfulBlogPost {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
      site {
        siteMetadata {
          content {
            creditComparatorName
          }
        }
      }
    }
  `);

  return (
    <MainLayout>
      <Seo
        title={`Despre ${site.siteMetadata.content.creditComparatorName}`}
        description={
          `${site.siteMetadata.content.creditComparatorName} care te ajuta să alegi cel mai bun credit`
        }
      />

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        className={classes.root}
      >
        <Box component="section" className={classes.sectionHead}>
          <Typography
            className={clsx(classes.pageHeaderTitle, classes.sectionSpace)}
            id="page-header-title"
            variant="h3"
            align="center"
          >
            {site.siteMetadata.content.creditComparatorName}
          </Typography>
          <Typography
            className={clsx(classes.pageHeaderSubtitle, classes.sectionSpace)}
            variant="h5"
            id="page-header-subtitle"
            align="justify"
          >
            <Link color="secondary" to="/credit-comparator">
              {site.siteMetadata.content.creditComparatorName}
            </Link>{" "}
            este un instrument care te ajuta să compari mai multe oferte de
            credite ipotecare.
          </Typography>
        </Box>

        <Box className={clsx(classes.sectionButtons, classes.sectionSpace)}>
          <Box
            className={classes.buttonsContainer}
          >
            <Link to="/credit-comparator">
              <Button 
                color="secondary" 
                variant="contained"
                endIcon={<ArrowForwardIcon/>}>
                Incepe acum
              </Button>
            </Link>
            <Link to="/blog/cum-utilizezi-comparatorul-de-credite">
              <Button endIcon={<ArrowForwardIcon/>}>Cum utilizez</Button>
            </Link>
          </Box>
        </Box>

        <Box
          component="section"
          className={clsx(classes.sectionHead, classes.sectionSpace)}
        >
          <Typography variant="h6">
            La ce te ajută {site.siteMetadata.content.creditComparatorName}?
            <ArticleList>
              <Typography align="justify">
                <ArticleListItem>
                  Ai mai multe oferte de credit ipotecar și vrei să vezi care e
                  mai bună
                </ArticleListItem>
                <ArticleListItem>
                  Vrei să afli cât te costă creditul ipotecar în dependență de
                  rata lunară
                </ArticleListItem>
                <ArticleListItem>
                  Ai deja un credit ipotecar și vrei să vezi cât economisești
                  dacă rambursezi anticipat
                </ArticleListItem>
              </Typography>
            </ArticleList>
          </Typography>
        </Box>
      </Box>

      {/* <ul>
      {posts.edges.map((post:any) => (
        <li key={post.node.slug}>
          <Link to={`/blog/${post.node.slug}`}>{post.node.title}</Link>
        </li>
      ))}
      </ul> */}
    </MainLayout>
  );
};

export default IndexPage;
