import { Box, Divider, makeStyles, Typography, withStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    // marginLeft: theme.spacing(2),
  },
}));

const ArticleListItem: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  return (
    <li className={classes.root}>
        {children}
    </li>
  );
};

export default ArticleListItem;
