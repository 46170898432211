import { Box, Divider, makeStyles, Typography, withStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
}));

const ArticleList: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  return (
    <ul className={classes.root}>
        {children}
    </ul>
  );
};

export default ArticleList;
